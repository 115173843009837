// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-full-view-tsx": () => import("./../../../src/templates/ArticleFullView.tsx" /* webpackChunkName: "component---src-templates-article-full-view-tsx" */),
  "component---src-templates-sub-category-article-page-tsx": () => import("./../../../src/templates/SubCategoryArticlePage.tsx" /* webpackChunkName: "component---src-templates-sub-category-article-page-tsx" */)
}

